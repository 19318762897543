@mixin border-radius($property) {
    -webkit-border-radius: $property;
    -moz-border-radius: $property;
    border-radius: $property;
}

@mixin transition($duration) {
    -moz-transition:all $duration;
    -o-transition:all $duration;
    -webkit-transition:all $duration;
    transition:all $duration;
}

@mixin transition($duration) {
    -moz-transition:all $duration;
    -o-transition:all $duration;
    -webkit-transition:all $duration;
    transition:all $duration;
}

@mixin rotate($rotate) {
    -webkit-transform: rotate($rotate);
    -moz-transform: rotate($rotate);
    -ms-transform: rotate($rotate);
    -o-transform: rotate($rotate);
}


// frontend editor
.ck-editor__editable_inline>:first-child {
    margin-top: inherit !important;
}
.ck-editor__editable_inline>:last-child {
    margin-bottom: 0px !important;
}

div.admin-recording {
    &.on {
        width:100vw;
        height: 100vh;
        position: fixed;
        top:0;
        left: 0;
        border:10px solid red;
        pointer-events: none;
    }
    
}
div.adminpanel {
    position: fixed;
    width:100%;
    min-height: 20px;
    background:#2B333E;
    color:white;
    left: 0px;
    bottom: 0px;
    z-index: 100;
    padding: 10px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    a.button {
        padding: 8px 13px;
        background:#0C131D;
        color:white;
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
        @include border-radius(5px);
        padding-left: 45px;
        position: relative;
        @include transition(0.3s);
        &:hover {
            text-decoration: none;
            background: #21B0F3;
            color:black;
        }
        &.edit {
            background:#10B53E;
            &:hover {
                background: #047323;
                background: #047323;
                color:white;
            }
            position: absolute;
            right: 10px;
            &.off {
                background:#0C131D;
            }
            &::before {
                background-image:url('../../images/admin/edit.svg');
            }
        }
        &::before {
            width:17px;
            height: 17px;
            background: url('../../images/admin/admin.svg') no-repeat center center;
            position: absolute;
            background-size: contain;
            top:10px;
            left: 15px;
            display: block;
            content: '';
        }
    }
}
.userinput {
    font-size: 20px;
    h2 {
        margin-top: 20px;
        margin-bottom: 30px;
    }
    a {
        display: inline-block;
        background: rgba(100,190,255,0.2);
        &:hover {
            color:white;
            text-decoration: none;
            background: rgba(100,190,255,1);
        }
    }
}